<template>
    <div class="home">
        <carousel class="main-carousel" :items-to-show="1" :autoplay="6500" :wrapAround="true" :transition="1500">

            <slide v-for="slide in CarouselImages" :key="slide">
                <img class="carousel-image" :src="img_root_url + slide" />
            </slide>

            <template #addons>
                <navigation />
                <pagination />
            </template>
        </carousel>

        <div class="welcom-text-wrapper">
            <p>
                Bienvenue sur le site de la Fémina Vicques
            </p>
        </div>

        <div class="wrapper about-text-wrapper">
            <h2>
                À propos de nous
            </h2>
            <p>
                Notre société propose des cours de gymnastique et d'athlétisme destinés à répondre aux besoins
                sportifs de chacun. Actuellement, la Fémina Vicques rassemble quelque 160 membres.
                Outre les manifestations sportives auxquelles la société prend part, la Fémina Vicques a également
                à cœur de promouvoir les activités qui renforcent les liens sociaux, à l'intérieur de ses groupes
                comme avec la collectivité villageoise et régionale.
            </p>

            <div class="sport-offers-wrapper">
                <router-link :to="{ name: 'Offers' }">
                    Vers nos offres sportives
                </router-link>
            </div>

        </div>

        <div class="wrapper social-networks-wrapper">
            <h2>
                Réseaux sociaux
            </h2>

            <div>
                <a href="https://www.facebook.com/people/F%C3%A9mina-Vicques-Gymnastique-Danse/100029087366561/"
                    target="_blank" rel="noopener noreferrer">
                    <img src="@/assets/sn-icon1.svg" class="no-drag" alt="facebook" />
                </a>

                <a href="https://www.instagram.com/feminavicques/" target="_blank" rel="noopener noreferrer">
                    <img :src="require('@/assets/sn-icon2.svg')" class="no-drag" alt="instagram" />
                </a>
            </div>
        </div>

        <div class="wrapper contact-wrapper">
            <h2>
                Nous contacter
            </h2>

            <form method="#" onsubmit="return false">
                <div>
                    <label for="name">Nom</label>
                    <input name="name" id="name" v-model="contactData.name" />
                </div>

                <div>
                    <label for="email">E-Mail</label>
                    <input type="email" name="email" id="email" v-model="contactData.email" />
                </div>

                <div>
                    <label for="message">Message</label>
                    <textarea id="message" name="message" v-model="contactData.message"></textarea>
                </div>

                <button @click="sendEmail()">
                    Envoyer
                </button>
            </form>
        </div>
    </div>
</template>

<script>

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
    name: 'Home',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },

    data: () => {
        return {
            CarouselImages: [
                "./images/home/Caroussel1.jpg",
                "./images/home/Caroussel2.jpg",
                "./images/home/Caroussel2-1.jpg",
                "./images/home/Caroussel3.jpg",
                "./images/home/Caroussel4.jpg",
                "./images/home/Caroussel4-1.jpg",
                "./images/home/Caroussel5.jpg",
                "./images/home/Caroussel6.jpg",
                "./images/home/Caroussel7.jpg",
                "./images/home/Caroussel8.jpg",
                "./images/home/Caroussel9.jpg",
                "./images/home/Caroussel10.jpg",
                "./images/home/Caroussel11.jpg",
               
            ],

            contactData: {
                name: '',
                email: '',
                message: ''
            },

            img_root_url: '//femina-vicques.ch/'
        }
    },

    methods: {
        sendEmail: function () {
            this.$sendMessage(this.contactData.name, this.contactData.email, this.contactData.message);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/scss/colors.scss";

.carousel-image {
    max-width: 100%;
    width: 100%;
    max-height: 700px;
    object-fit: cover;
}

h2 {
    font-weight: 200;
    font-size: 2rem;
    text-transform: uppercase;
    color: $femina-color-green;
    margin-bottom: 2rem;
}

.welcom-text-wrapper {
    text-align: center;
    font-size: 3rem;
    margin: 8rem 20% 10rem;
    color: $femina-color-blue;
    text-transform: uppercase;
}

.wrapper {
    padding: 5rem 25% 8rem;
    text-align: left;
}



.about-text-wrapper {
    color: #282B26;
    background-color: #f8f8f8;

    &>p {
        text-align: justify;
        font-size: 1.4rem;
        line-height: 1.5;
    }

    &>.sport-offers-wrapper {
        margin-top: 6rem;
        text-align: center;

        &>a {
            text-decoration: none;
            padding: 25px 45px;
            border: 2px solid $femina-color-green;
            background-color: $femina-color-green;
            color: #f8f8f8;
            font-size: 24px;
            transition: 0.3s ease-in-out;
            line-height: 1;

            text-transform: uppercase;
            border-radius: 7px;

            &:hover {
                cursor: pointer;
                background: #f8f8f8;
                color: $femina-color-green;
            }
        }
    }
}

.social-networks-wrapper {
    &>h2 {
        color: $femina-color-blue;
    }

    &>div {
        margin-top: 3rem;

        display: flex;
        justify-content: space-around;

        &>a>img {
            width: 128px;
        }
    }
}

.contact-wrapper {
    color: #282B26;
    background-color: #f8f8f8;

    &>form {
        &>div {
            width: 100%;

            margin-bottom: 1.5rem;

            &>label {
                margin-bottom: 0.5rem;
                font-size: 1.2rem;
                display: block;
            }

            &>input {
                width: 100%;
                border: 1px solid #aaa;
                border-color: #ddd;
                border-radius: 4px;
                padding: 13px 14px;
                font-size: 1.3rem;
                color: #444;

                transition: border-color 0.3s ease-in-out;

                &:focus {
                    border-color: #444;
                }

                &:focus-visible {
                    outline: none;
                }
            }

            &>textarea {
                padding: 13px 14px;
                border: 1px solid #aaa;
                border-color: #ddd;
                border-radius: 4px;
                width: 100%;
                height: 8rem;

                transition: border-color 0.3s ease-in-out;

                &:focus {
                    border-color: #444;
                }

                &:focus-visible {
                    outline: none;
                }
            }
        }

        &>button {
            width: 100%;
            text-align: center;
            padding: 20px;
            text-transform: uppercase;
            background-color: #222;
            color: white;
            border-radius: 4px;

            transition: 0.2s ease-in-out;

            &:hover {
                cursor: pointer;
                color: #222;
                background-color: white;
            }
        }
    }
}

@media (max-width: 1050px) {
    .about-text-wrapper>.sport-offers-wrapper>a {
        font-size: 1.4rem;
    }
}

@media (max-width: 912px) {
    .wrapper {
        padding: 5rem 10% 8rem;
        text-align: left;
    }
}

@media (max-width: 790px) {
    .welcom-text-wrapper {
        font-size: 2rem;
        margin: 5rem 20% 7rem;
    }

    .about-text-wrapper>.sport-offers-wrapper {
        margin-top: 4.5rem;
    }
}

@media (max-width: 574px) {
    .about-text-wrapper>.sport-offers-wrapper>a {
        font-size: 1rem;
    }

    .welcom-text-wrapper {
        font-size: 1.8rem;
        margin: 4rem 15% 6rem;
    }

    .wrapper {
        padding: 2rem 8% 5rem;
        text-align: left;
    }
}

@media (max-width: 450px) {
    .about-text-wrapper>.sport-offers-wrapper>a {
        padding: 15px 25px
    }
}

@media (min-width: 1440px) {
    .wrapper {
        padding: 5rem 30% 8rem;
    }
}

@media (min-width: 1840px) {
    .wrapper {
        padding: 5rem 35% 8rem;
    }
}
</style>

<style lang="scss">
@import "@/scss/colors.scss";

$button-lat-offset: 20px;

.carousel__next,
.carousel__prev {

    opacity: 0.8;
    background: none;

    transform: translate(0, -50%);
    min-height: 10%;

    transition: 0.2s ease-in-out;

    &:hover {
        opacity: 1;
    }
}

.carousel__pagination-button {
    height: 12px;
    width: 12px;
    border-radius: 6px;
    opacity: 0.5;
    background-color: $femina-color-green;

    transition: opacity 0.3s ease-in-out;
}

.carousel__pagination-button--active {
    opacity: 1;
    background-color: $femina-color-green;
}

.carousel__next {
    margin-right: $button-lat-offset;
}

.carousel__prev {
    margin-left: $button-lat-offset;
}

.carousel__pagination {
    position: relative;
    top: -40px;
    margin: 0;
    padding: 10px;
    display: none;
}

@media (max-width: 574px) {
    .carousel__pagination-button {
        height: 8px;
        width: 10px;
    }
}
</style>
