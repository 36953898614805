import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Offers from '../views/Offers.vue'
import Athletism from '../views/Athletism.vue'
import Charter from '../views/Charter.vue'
import Costume from '../views/Costume.vue'
import Membership from '../views/Membership.vue'
import Contact from '../views/Contact.vue'
import Gymnastics from '../views/Gymnastics.vue'
import GymDance from '../views/GymDance.vue'
import Gallery from '../views/Gallery.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/offres',
    name: 'Offers',
    component: Offers
  },
  {
    path: '/offres/athletisme',
    name: 'Athletism',
    component: Athletism
  },
  {
    path: '/offres/gymdanse',
    name: 'Gym-Dance',
    component: GymDance
  },
  {
    path: '/offres/gymnastique',
    name: 'Gymnastics',
    component: Gymnastics
  },
  {
    path: '/charte',
    name: 'Charter',
    component: Charter
  },
  {
    path: '/costumes',
    name: 'Costumes',
    component: Costume
  },
  {
    path: '/adhesion',
    name: 'Membership',
    component: Membership
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/galerie/:event?',
    name: 'Galerie',
    component: Gallery
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
