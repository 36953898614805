<template>
    <section>
        <div class="wrapper contact-wrapper">
            <h2>
                Nous contacter
            </h2>

            <p>
                Formulaire en maintenance! Veuillez nous contacter par mail à studercm@bluewin.ch
            </p>
            <form method="#" onsubmit="return false">
                <div>
                    <label for="name">Nom</label>
                    <input name="name" id="name" v-model="name"/>
                </div>

                <div>
                    <label for="email">E-Mail</label>
                    <input type="email" name="email" id="email" v-model="email"/>
                </div>

                <div>
                    <label for="message">Message</label>
                    <textarea id="message" name="message" v-model="message"></textarea>
                </div>

                <button @click="sendEmail()">
                    Envoyer
                </button>
            </form>
        </div>
    </section>
</template>

<script>

export default {
    data: () => {
        return {
            name: '',
            email: '',
            message: ''
        }
    },

    methods: {
        sendEmail: function() {
            this.$sendMessage(this.name, this.email, this.message);
        }
    }
}
</script>

<style lang="scss" scoped>

@import "@/scss/colors.scss";

section {
    margin: auto;
    max-width: 600px;
}

.contact-wrapper {
    max-width: 600px;
    color: #282B26;

    & > h2 {
        padding-top: 2rem;
        color: $femina-color-blue;
        font-weight: 100;
        font-size: 2.5rem;
        text-transform: uppercase;
    }

    & > form {
        & > div {
            width: 100%;

            margin-bottom: 1.5rem;

            & > label {
                margin-bottom: 0.5rem;
                font-size: 1.2rem;
                display: block;
            }

            & > input {
                width: 100%;
                border: 1px solid #ccc;
                border-color: #ddd;
                border-radius: 4px;
                padding: 13px 14px;
                font-size: 1.3rem;
                color: #444;

                transition: border-color 0.3s ease-in-out;

                &:focus {
                    border-color: #444;
                }

                &:focus-visible {
                    outline: none;
                }
            }

            & > textarea {
                padding: 13px 14px;
                border: 1px solid #aaa;
                border-color: #ddd;
                border-radius: 4px;
                width: 100%;
                height: 8rem;

                transition: border-color 0.3s ease-in-out;

                &:focus {
                    border-color: #444;
                }

                &:focus-visible {
                    outline: none;
                }
            }
        }  

        & > button {
            width: 100%;
            text-align: center;
            padding: 20px;
            text-transform: uppercase;
            background-color: #222;
            color: white;
            border-radius: 4px;

            transition: 0.2s ease-in-out;

            &:hover {
                cursor: pointer;
                color: #222;
                background-color: white;
            }
        }
    } 
}

@media (max-width: 670px) {
    .contact-wrapper {
        
        & > h2 {
            margin-left: 2rem;
        }

        & > form {
            margin: auto;
            max-width: 80%;
        }
    }
}
</style>
